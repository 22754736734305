import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { AppBar, Box, Button, Toolbar, Typography } from "@material-ui/core"

const Header = ({ siteTitle }) => (
  <header
    style={
      {
        // removed for now
        // marginBottom: `1.45rem`,
      }
    }
  >
    <AppBar position="static">
      <Toolbar>
        <Box
          style={{
            margin: `0`,
            padding: `1.45rem 1.0875rem`,
            flex: 1,
          }}
        >
          <Typography
            variant="h4"
            style={{ margin: 0, textAlign: `left` }}
            gutterBottom
          >
            <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              {siteTitle}
            </Link>
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ margin: 0, textAlign: `left` }}
          >
            <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              Not all validators are created equal
            </Link>
          </Typography>
        </Box>

        <Button color="inherit" component={Link} to="/nfts">Browse NFTs</Button>
        <Button color="inherit" component={Link} to="/contact">Contact Us</Button>
      </Toolbar>
    </AppBar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
